import * as d3 from "d3-format";

export const M_DASH = "-";

const defaultConfig = {
	decimalSeparator: ".",
	thousandsSeparator: "",
	grouping: 3,
	minus: "−",
	currencySymbol: "$",
	invalidSymbol: M_DASH,
	infinitySymbol: M_DASH,
};

const makeFormatter = (config = {}) => {
	const { format } = d3.formatLocale({
		decimal: config.decimalSeparator,
		thousands: config.thousandsSeparator,
		grouping: [config.grouping],
		currency: [config.currencySymbol, ""],
		minus: config.minus,
	});

	return format;
};

const isStrictZero = (i) => i === 0;

export const isValidNumber = (i) => {
	// Default to NaN to avoid coercing empty string, null & undefined to 0
	const asNumberOrNaN = (n) => Number(n || NaN);
	// Check is a number by checking is not "not a number"
	const isNumber = (n) => !Number.isNaN(n);

	return isStrictZero(i) || isNumber(asNumberOrNaN(i));
};

const defaultSignFrom = (config) => config?.sign ?? "-";

const precisionSpecifierFrom = (config) => (value) => {
	/**
	 * 'minimumDecimalPlaces' - padds value with 0s up to minimum places.
	 * Leaves values with more than minimum unchanged.
	 */
	if (config?.minimumDecimalPlaces) {
		const { minimumDecimalPlaces } = config;
		const hasMoreThanMinimumDecimalPlaces = Boolean(
			(Number(value) * 10 ** (minimumDecimalPlaces - 1)) % 1,
		);
		return hasMoreThanMinimumDecimalPlaces ? "~" : `.${minimumDecimalPlaces}`;
	}

	/**
	 * 'precision' - rounds to the given precision.
	 * Prefer 'minimumDecimalPlaces' where possible to ensure values are consistent with api.
	 */
	if (config?.precision) {
		return `.${config?.precision}`;
	}

	/**
	 * Trim insignificant trailing zeros across all format types.
	 */
	return "~";
};

const makeFormatIfValid = (config, ...formatterArgs) => {
	const resolvedConfig = { ...defaultConfig, ...config };
	const format = makeFormatter(resolvedConfig)(...formatterArgs);

	const formatIfValid = (value) => {
		const { invalidSymbol, infinitySymbol } = resolvedConfig;
		if ("invalidSymbol" in resolvedConfig && !isValidNumber(value)) {
			return invalidSymbol;
		}

		if ("infinitySymbol" in resolvedConfig && Math.abs(value) === Infinity) {
			return infinitySymbol;
		}

		return format(value);
	};

	return formatIfValid;
};

const formatFromType =
	({ type, signFrom = defaultSignFrom }) =>
	(config) => {
		const precisionFor = precisionSpecifierFrom(config);
		const signSpecifier = signFrom(config);

		return (value) => {
			const specifier = `${signSpecifier}${precisionFor(value)}${type}`;

			return makeFormatIfValid(config, specifier)(value);
		};
	};

export const formatFloatFor = formatFromType({ type: "f" });

/**
 * Format with an SI prefix - aka. 'Scientific Notation' or 'International System of Units'
 * This formatter can be used to 'truncate' the length of a number, so ensure the precision
 * matches the use case. Note precision here sets significant figures, not decimal places.
 * For example, formatting $12,000,000 as $12M may be appropriate for marketing purposes,
 * but not for reporting.
 */
export const formatSIFor = formatFromType({ type: "s" });

export const formatInteger = makeFormatIfValid(
	{ thousandsSeparator: "," },
	",d",
);

export const formatPercentageFor = formatFromType({
	type: "%",
	signFrom: (config) => (config.sign ? ` =${config.sign}` : ""),
});

export const formatFractionAsPercentageFor =
	(config) =>
	({ numerator, denominator } = {}) =>
		formatPercentageFor(config)(numerator / denominator);

export const formatCurrencyFor = (config) => {
	const precisionFor = precisionSpecifierFrom({
		precision: 2,
		...config,
	});

	return (value) => {
		const specifier = `${defaultSignFrom(config)}$,${precisionFor(value)}f`;

		return makeFormatIfValid(config, specifier)(value);
	};
};

export const formatBytes = (bytes, decimals = 2) => {
	const { invalidSymbol } = defaultConfig;
	if (!isValidNumber(bytes) || bytes < 0) {
		return invalidSymbol;
	}
	if (isStrictZero(bytes)) {
		return "0 Bytes";
	}
	const k = 1000;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
