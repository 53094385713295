import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";

import { Carousel, useCarouselState } from "../Carousel";
import type { GalleryImageProps } from "./ImageGallery";

type GalleryFullScreenProps = {
	startIndex: number;
	images: GalleryImageProps[];
};

export const GalleryFullScreen = ({
	startIndex,
	images,
}: GalleryFullScreenProps) => {
	const [selectedIndex, setSelectedIndex] = useState(startIndex);

	const carouselState = useCarouselState({
		startIndex,
	});
	const thumbState = useCarouselState({
		dragFree: true,
		align: "center",
	});

	const [, embla] = carouselState;
	const [, emblaThumbs] = thumbState;

	const onThumbClick = useCallback(
		(index: number) => {
			if (embla && emblaThumbs) {
				if (emblaThumbs.clickAllowed()) {
					embla.scrollTo(index);
				}
			}
		},
		[embla, emblaThumbs],
	);

	const onSelect = useCallback(() => {
		if (embla && emblaThumbs) {
			setSelectedIndex(embla.selectedScrollSnap());
			emblaThumbs.scrollTo(embla.selectedScrollSnap());
		}
	}, [embla, emblaThumbs, setSelectedIndex]);

	useEffect(() => {
		if (embla) {
			onSelect();
			embla.on("select", onSelect);
		}
	}, [embla, onSelect]);

	return (
		<div className="grid flex-auto gap-4">
			<Carousel
				className="self-end lg:px-[15vw] xl:px-[20vw]"
				slideGap="gap-6"
				carouselState={carouselState}
			>
				{images?.map(({ image }) => (
					<div className="group w-full flex-shrink-0 overflow-hidden rounded-md">
						{image}
					</div>
				))}
			</Carousel>
			<Carousel
				showArrows={false}
				className="self-center md:flex-shrink md:px-[10vw] xl:px-[15vw]"
				carouselState={thumbState}
			>
				{images?.map(({ thumbnail }, i) => (
					<button
						className={clsx(
							"group w-1/3 flex-shrink-0 transform transition-all duration-300 md:w-1/4 lg:w-1/6 lg:max-w-[200px]",
							selectedIndex === i && ["scale-100 opacity-100"],
							selectedIndex !== i && ["scale-95 opacity-50"],
						)}
						onClick={() => onThumbClick(i)}
						type="button"
					>
						{thumbnail}
					</button>
				))}
			</Carousel>
		</div>
	);
};
